<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">View Payment</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Customer</label>
        <vs-input
          class="w-full input-readonly"
          name="Customer"
          v-model="create.customer"
          readonly
        />
        <!-- <multiselect
          class="selectExample"
          v-model="create.supplier"
          :options="optionSupplier"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          @search-change="getOptionSupplier"
          :disabled="true"
          readonly
        > -->
          <!-- <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </div>
          </template> -->
        <!-- </multiselect> -->
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Payment Method</label>
        <vs-input
          class="w-full input-readonly"
          name="PaymentMethod"
          v-model="create.method"
          readonly
        />
      </div>

      <div class="vx-col w-full" v-if="selected.PaymentMethod != 'Cash'">
        <label class="vs-input--label">Operating Unit Bank</label>
        <multiselect
          class="selectExample"
          v-model="create.operatingUnitBank"
          :options="optionOperatingUnitBank"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">
                {{ dt.option.BankName + " " + dt.option.AccountNumber }}
                <br />
                {{ dt.option.AccountName }}
              </span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">
                {{ dt.option.BankName + " " + dt.option.AccountNumber }}
                <br />
                {{ dt.option.AccountName }}
              </span>
            </div>
          </template>
        </multiselect>
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Date Payment</label>
        <vs-input
          class="w-full input-readonly"
          name="DatePayment"
          v-model="create.date_payment"
          readonly
        />
        <!-- <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Date Payment"
          v-model="create.date_payment"
          placeholder="Date Deposit"
          :disabled-dates="{ from: new Date() }"
          :disabled="true"
        ></datepicker> -->
        <!-- disabled-dates="{from: new Date()}" -->
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Reference Code</label>
        <vs-input
          class="w-full input-readonly"
          name="ReferenceCode"
          v-model="create.reference_code"
          readonly
        />
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Amount</label>
        <vs-input
          class="w-full input-readonly"
          name="ReferenceCode"
          v-model="create.amount"
          readonly
        />
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Note</label>
        <vs-textarea v-model="create.note" disabled style="background-color: #e6e6e6"/>
      </div>

      <div class="vx-col w-full" v-if="invoice.length > 0">
        <vs-divider style="width: 100%"> Invoice </vs-divider>

        <vs-table
          stripe
          border
          description
          class="w-full"
          :sst="true"
          :data="invoice"
        >
          <template slot="thead">
            <vs-td style="text-align: center"><b>Code</b></vs-td>
            <vs-td style="text-align: center"><b>Value</b></vs-td>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data"
              :class="data[indextr].class"
            >
              <vs-td style="text-align: center">
                {{ data[indextr].code }}
              </vs-td>
              <vs-td style="text-align: center">
                {{  data[indextr].value.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")  }}
              </vs-td>
            </vs-tr>
          </template>
          <br />
        </vs-table>
      </div>

      <vs-divider style="width: 100%; margin-left: 2%; display: none;">Attachment</vs-divider>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%; display: none;">
        <div class="vx-col sm:w-1/3 w-full">
          <span>File</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <input
              id="fileInput"
              name="file"
              class="w-full inputx"
              type="file"
              ref="file"
              multiple="multiple"
              accept=".jpg, .jpeg, .png .pdf"
            />
          </div>
        </div>
      </div>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%; display: none;">
        <div class="vx-col sm:w-1/3 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <vs-button class="mr-3 mb-2" @click="handleAttachment"
              >Add Attachment</vs-button
            >
          </div>
        </div>
      </div>

      <div class="vx-col w-full" v-if="selected.PaymentMethod != 'Cash'">
        <br />
        <vs-button class="mb-2" v-on:click="handleSubmit"
          >Update</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  components: {
    Datepicker,
  },
  props: {
    selected: Object,
    option: Object,
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        lengthFile: 0,
        file: [],
        fileAttachment: [],
        search: "",
        supplier: null,
        operatingUnitBank: null,
        supplierBank: null,
        additionalValue: 0,
        costCenter: null,
        coa: null,
        optionCostCenter: [],
        optionCoa: [],
        bank: [],
        paymentProposal: [],
        optionSupplier: [],
        optionBankSupplier: [],
        optionPaymentProposal: [],
        optionTerritory: [],
        optionOperatingUnitBank: [],
        optionMethod: ["Giro", "Cheque", "Transfer"],
        table: {
          data: [],
          detail_payment: [],
          supplier_deposit: [],
          supplier_deposit_line: [],
        },
        create: {
          supplier: null,
          operatingUnitBank: null,
          method: "Giro",
          territory: null,
          reference_code: "",
          date_payment: "",
          date_giro: "",
          date_posting: "",
          amount: 0,
          number_of: "",
        },
        invoice: [],
      };
    },
    getGeneralForm() {
      this.$http
        .get("/api/v1/other-payment/for-dn/form-general/0")
        .then((r) => {
          // this.optionTerritory = r.data.territory
          
          this.optionTerritory = r.data.territories;
          this.optionTerritory.map((v) => {
            if (this.selected.TerritoryID == v.ID) {
              this.create.territory = v;
            }
          });
          // this.optionBank = r.data.bank
          // this.optionOperatingUnitBank = r.data.operatingUnitBank
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getOptionSupplierBank() {
      this.$http
        .get("/api/v1/pi/supplier-account/" + this.selected.PartnerID)
        .then((r) => {
          console.log(this.selected, " selected sini ---");
          this.optionBankSupplier = r.data.account;
          this.optionBankSupplier.map((v) => {
            console.log(
              this.selected,
              " selected sini -----",
              v.BankID == this.selected.SupplierBankID,
              v.AccountNumber == this.selected.SupplierAccountNumber
            );
            if (
              v.BankID == this.selected.SupplierBankID &&
              v.AccountNumber == this.selected.SupplierAccountNumber
            ) {
              this.create.supplierBank = v;
            }
          });
          console.log(r);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    formatPrice(angka, prefix = "") {
      return angka
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getData() {
      this.$vs.loading();
      console.log("GET DATA")
      this.table.detail_payment = []
      this.table.supplier_deposit = []
      this.table.supplier_deposit_line = []
      this.$http
        .get("/api/v1/other-payment-line/" + this.selected.ID)
        .then((resp) => {
          // this.$vs.loading.close();
          if (resp.code == 200) {
           
            // if (resp.data.unpaid_value == 0) {
            // this.$vs.notify({
            //   color: "success",
            //   title: "Success",
            //   position: "top-right",
            //   iconPack: "feather",
            //   icon: "icon-x-circle",
            // });
            // this.handleClose();
            // return
            // }
            this.table.detail_payment = resp.data.detail_payment;
            this.table.supplier_deposit = resp.data.supplier_deposit;

            this.table.supplier_deposit_line = resp.data.supplier_deposit_line;
            console.log("resp.data", resp.data)
            console.log("resp.data.supplier_deposit", resp.data.supplier_deposit)
            console.log("resp.data.supplier_deposit_line", resp.data.supplier_deposit_line)
            // this.table.payment = resp.data.payment;
            // this.table.data_payment = resp.data.detail_payment;
            // console.log("resp.data.detail_payment", resp.data.detail_payment);
             this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    removeSelectedDN(...args) {
      for (var i = 0; i < this.debitNote.length; i++) {
        console.log(args[0].ID, this.applyID[i]);
        if (args[0].ID == this.applyID[i]) {
          this.applyID.splice(i, 1);
          this.applyValue.splice(i, 1);
          break;
        }
      }
      console.log(args, "args");

      this.maxSelectedDN = 999;
    },
    processSelectedDN(json) {
      this.$loa;
      console.log(json);
      json.map((v, r) => {
        if (r > 0 && v.length > 0) {
          const filter = this.optionDebitNote.filter((subv) => {
            return v[0] == subv.Code;
          });
          if (filter.length > 0) {
            const unUsed =
              parseFloat(filter[0].TotalDnValue) -
              parseFloat(filter[0].UsedDnValue);
            console.log(unUsed, v[2]);
            if (unUsed >= parseFloat(v[2])) {
              const checkSelected = this.debitNote.filter((subv) => {
                return v[0] == subv.Code;
              });
              if (checkSelected.length < 1) {
                this.debitNote.push(filter[0]);
                this.applyID.push(filter[0].ID);
                if (this.applyValue.length < this.debitNote.length) {
                  this.applyValue.push(parseFloat(v[2]));
                } else {
                  this.applyValue[this.applyValue.length - 1] = parseFloat(
                    v[2]
                  );
                }
              } else {
                this.$vs.notify({
                  color: "warning",
                  title: "Information",
                  text: v[0] + " already selected",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }
            } else {
              this.$vs.notify({
                color: "warning",
                title: "Information",
                text: v[0] + " value greater than remaining debit note",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          } else {
            this.$vs.notify({
              color: "warning",
              title: "Information",
              text: v[0] + " not found",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          console.log(filter);
          console.log(v, r, "r");
        }
      });
      // this.optionDebitNote.map(item => {
      //   // console.log(item, 'item')
      // })
    },
    handleSubmit() {
      if (this.create.operatingUnitBank == null) {
        this.$vs.notify({
          title: "Error",
          text: "Please choose a Bank",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });

        return;
      }
      this.$vs.loading();
      const params = {
        id: this.selected.ID,
        bank_id: this.create.operatingUnitBank.BankID,
        bank_name: this.create.operatingUnitBank.BankName,
        bank_account_name: this.create.operatingUnitBank.AccountName,
        bank_account_number: this.create.operatingUnitBank.AccountNumber,
      };
      console.log(params, "params");
      this.$http
        .put("/api/v1/payment/bank/update", params)
        .then((result) => {
          this.$vs.loading.close();
          if (result.code == 200) {
            this.handleClose();
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.handleClose();
          } else {
            this.$vs.notify({
              title: "Error",
              text: result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        })
        .catch((e) => {});
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("closeDetail", true);
    },
    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        this.lengthFile = this.$refs.file.files.length;
        this.file = this.$refs.file.files;
        // for(let i =0; i < this.$refs.file.files.length; i++) {

        this.uploadData(0);
        // }
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    uploadData(array) {
      console.log(this.lengthFile, this.file, array);
      if (this.lengthFile <= array) {
        // this.$emit("closeDetail", true);
        // this.$vs.loading.close();
        document.querySelector("#fileInput").value = "";
        return;
      }
      this.$vs.loading();
      this.$http
        .post("/api/v1/other-payment/upload-file", this.paramUpload(array), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.fileAttachment = [];
            resp.data.attachment.map((v) => {
              this.fileAttachment.push({
                ListID: v.ListID,
                NameFile: v.NameFile,
                PathFile: v.PathFile,
              });
            });
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.uploadData(++array);
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
          this.uploadData(++array);
        });
    },
    paramUpload(array) {
      var form = new FormData();
      form.append("id", this.selected.ID);
      form.append("file", this.file[array]);
      return form;
    },
    handleDeleteAttachment(id) {
      this.$vs.loading();
      var form = new FormData();
      form.append("list_id", id);
      form.append("id", this.selected.ID);
      this.$http
        .post("/api/v1/other-payment/delete-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.fileAttachment = this.fileAttachment.filter((v) => {
              return v.ListID != id;
            });
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    dateFormat(value) {
      return moment(String(value)).format("DD-MM-YYYY");
    },
    getBankForm() {
      this.$http
        .get(
          "/api/v1/other-payment/for-dn/form-general/" +
            this.selected.TerritoryID
        )
        .then((r) => {
          this.optionOperatingUnitBank = r.data.operatingUnitBank;
          this.optionOperatingUnitBank.map((v) => {
            if (v.AccountNumber == this.selected.BankAccountNumber) {
              this.create.operatingUnitBank = v;
            }
          });
        })
        .catch((e) => {});
    },
    getInvoiceData() {
      this.invoice = [];
      if (this.selected.CodeInvoice != "") {
        let codeInvoice = this.selected.CodeInvoice.split(';')
        let valInvoice = this.selected.InvoiceValue.split(';')

        for (let i = 0; i < codeInvoice.length; i++) {
          this.invoice.push({
            code: codeInvoice[i],
            value: valInvoice[i],
          })
        }
      }
    },
  },
  mounted() {
    console.log("mounted",this.selected)

    this.create.customer = this.selected.CustomerCode+" - "+this.selected.CustomerName
    this.create.method = this.selected.PaymentMethod
    this.create.reference_code = this.selected.ReferenceCode;
    this.create.amount = this.formatPrice(this.selected.Amount);
    this.create.date_payment = this.dateFormat(this.selected.DatePayment);
    this.create.note = this.selected.Note;
    this.getBankForm();
    this.getInvoiceData();
  },
  computed: {},
  watch: {
    
    "selected.PartnerID"() {
      // console.log(this.create.supplier)
    },
    // "create.territory.ID"() {
    //   this.getBankForm()
    // },
    "selected.ID"() {
      console.log(this.selected, "this.selected WATCH")
      this.create.customer = this.selected.CustomerCode+" - "+this.selected.CustomerName
      this.create.method = this.selected.PaymentMethod
      this.create.reference_code = this.selected.ReferenceCode;
      this.create.amount = this.formatPrice(this.selected.Amount);
      this.create.date_payment = this.dateFormat(this.selected.DatePayment);
      this.create.note = this.selected.Note;
      this.getBankForm();
      this.getInvoiceData();
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>